export default {
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Változtatások mentése"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés..."])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza"])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztos vagy?"])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID másolása"])},
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ma ", _interpolate(_list(0)), "-kor"])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tegnap ", _interpolate(_list(0)), "-kor"])}
  },
  "message-area": {
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs engedélyed hogy ebbe a csatornába beszélhess."])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem Csatlakozva"])},
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach a message"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írd ide az üzeneted."])},
    "back-to-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza Az Aljára"])},
    "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új Üzenetek"])}
  },
  "upload-dialog": {
    "compress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sűrítés"])},
    "upload-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltsd fel"])}
  },
  "user-context": {
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil Megtekintése"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangok Szerkesztése"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirugás"])},
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bannolás"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Másolás Felhasználó:Tag"])}
  },
  "message-context": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idézés"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesztés"])}
  },
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvasottként megjelőlés"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerver Beállítások"])},
    "leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilépés a Szerverből"])}
  },
  "channel-context": {
    "mute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna némitása"])},
    "unmute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna némitásának feloldás"])}
  },
  "settings": {
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiók"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzenetek"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojik kezelése"])},
      "call-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hívási beállítások"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indulási beállítások"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Aktivítás"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botok Kezelése"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felület"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiók Törlése"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Változtatások"])},
      "wip-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folyamatban levő funciók"])}
    },
    "account": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesz A Profilod"])},
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó Cserélése"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Drive Fiók Újralinkelése"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Drive Fiók Linkelése"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Több Profil"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezt Az Információt Mindenki Láthassa."])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cseréld az értesítési beállításaid."])},
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítés Hang"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csinál egy menő hangot hogy felvegye a figyelmed."])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azonnali Értesítés"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapj értesítéseket akkor is ha az applikáció be van zárva."])}
    },
    "manage-emojis": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltsd fel a saját menő emojiidat ingyenesen! Az emojik 3MB méret alatt kell legyenek."])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Támogatott fájl típusok: png, jpg, gif"])},
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojik Hozzáadása"])}
    },
    "startup-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desktop Applikáció Beindulási beállítások"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogy tudd változtatni ezeket a beállításokat le kell töltsed Nertivia desktop applikációt."])},
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nertivia automatikus kinyítása beinduláskor"])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osszad meg hogy éppen milyen játekot játszol vagy appot használsz. Ez helyettesíteni fogja a sajátosított szátuszod és vissza fogja állítani amint az applikáció be lett zárva."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogy tudd ezt használni, le kell töltsed a Nertivia desktop appot."])}
    },
    "manage-bots": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Készíts bot felhasználokat akik engedni fogják hogy használd a mi API-nk hogy készíts sajátosított botot."])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adj egy bot parancsot hogy a felhasználok tudhassák mit tud a botod csinálni."])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot Készítése"])},
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parancs Hozzáadása"])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot Szerkesztése"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parancsok Szerkesztése"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghívás link készítése"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghívás link másolása"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Kezelése"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Megjelenítése"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Másolása"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Visszaállítása"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot Törlése"])}
    },
    "delete-account": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éppen a fiókod fogod törölni"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi fog törlődni"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nem fog törlődni"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az üzeneteid melyeket publikus szerverekbe küldted"])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Követelmények"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilépés/Törlés összes szervert"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botok Törlése"])},
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiók Törlése"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Újra töltés szükséges lehet nyelv cserélés után. Hozzájárulhat a GitHub-tárhelyen található PR-üzenet benyújtásával."])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fordítok"])}
    },
    "interface": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cseréld a Nertivia megjelenését."])},
      "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Színek"])},
      "time-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idő Formátum"])},
      "default-theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alap Téma"])},
      "amoled-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amoled Téma"])},
      "halloween-2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halloween 2020"])},
      "predefined-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előre Meghatározott Témák"])},
      "custom-css": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sajátósított CSS"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Általános"])},
      "navigation-bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigációs Sáv"])}
    },
    "wip-features": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engedélyezz nem befejezett vagy kisérleti funkciókat."])},
      "none-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy kisérleti funkció se elérhető jelenleg."])}
    }
  },
  "server-settings": {
    "permissions": {
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engedélyek"])},
      "admin": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adminisztrátor"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engedélyezi az ÖSSZES engedélyt"])}
      },
      "send-messages": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzenetek küldése"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldj üzeneteket a szerver csatornáiba"])}
      },
      "manage-roles": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangok Kezelése"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Változtass vagy törölj Rangot"])}
      },
      "manage-channels": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatornák kezelése"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Változtass vagy törölj csatornákat"])}
      },
      "kick-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirúgás"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználo kirúgása"])}
      },
      "ban-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirúgás és csatlakozás korlátozása egy felhasználónak"])}
      }
    },
    "tab-names": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Általános"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatornák Kezelése"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangok Kezelése"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghívok Kezelése"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerver Láthatósága"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítések Kezelése"])},
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bannolt Felhasználók"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók Kezelése"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerver Törlése"])}
    },
    "manage-roles": {
      "manage-your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangok Kezelése."])},
      "default-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alap Rang"])},
      "bot-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot Rang"])},
      "create-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang Készítése"])},
      "notice-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezt a rangot nem törölheted vagy adhatod egy felhasználónak mivel egy bot rangja. Távólitsd el a botot hogy eltünjőn a rang."])},
      "notice-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezt a rangot nem lehet törölni vagy el venni egy felhasználótól mivel ez minden felhasználóhóz hozzá van adva."])},
      "pick-role-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szín Választása"])},
      "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang Neve"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások"])},
      "delete-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang Törlése"])},
      "deleting-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang Törlődik..."])},
      "hide-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang Eltüntetése"])}
    },
    "general": {
      "general-server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Általános Szerver Beállítások"])}
    },
    "manage-channels": {
      "manage-your-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatornák Kezelése"])},
      "delete-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna Törlése"])},
      "deleting-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna Törlődik..."])},
      "channel-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna Nevee"])},
      "default-channel-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez egy alapértelmezett csatorna. Cserélheted az alapértelmezett csatornát a beállításokban. Alapértelmezett csatornákat nem törölhetsz."])},
      "create-new-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új Csatorna Készítése"])},
      "permission": {
        "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzenetek Küldése"])}
      },
      "rate-limit-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate Limit (másodpercek)"])},
      "text-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szöveg Csatorna"])},
      "html-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML Csatorna (Munka Alatt)"])}
    },
    "banned-users": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbannolhat felhasználókat innen"])},
      "unbanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbannolás folyamatban..."])},
      "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbannolás"])}
    },
    "manage-invites": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak az adminisztrátorok láthassák mindenki meghívóját."])},
      "create-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghívó Készítése"])},
      "custom-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sajátosított Meghívó"])},
      "save-custom-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sajátosított Meghívó Elmentése"])},
      "create-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghívo Készítése"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Készítő"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használatok"])}
    },
    "manage-users": {
      "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználó keresése"])}
    },
    "delete-server": {
      "type-server-name-to-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írd be a szerver nevét megerősítéshez"])},
      "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés..."])}
    },
    "notifications": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítési Beállítások Változtatása."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezek csak a te értesíteseid változtassák és másokét nem."])},
      "enable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítések Engedélyezése"])},
      "mute-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hang Némítása"])},
      "disable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítések Tiltása"])}
    }
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " éppen ír..."])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " és ", _interpolate(_list(1)), " éppen ír..."])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ", _interpolate(_list(1)), " és ", _interpolate(_list(2)), " éppen ír..."])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-an éppen írnak..."])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barátok"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem Régiek"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elmentett Jegyzetek"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barátnak Jelölés"])}
  },
  "dashboard-tab": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítések"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomj meg hogy állíts be sajátosított sztátuszt"])},
    "caught-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mind Felkapott!"])},
    "direct-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privát Üzenetek"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerverek"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felfedezés"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítópult"])}
  },
  "add-friend-popout": {
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelölj be egy barátot a nevének és tagjének ide irásával."])},
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelölés folyamatban..."])}
  },
  "add-server-popout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerver Hozzáadása"])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakozz egy szervert úgy hogy be irjot a meghívási kódját."])},
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Készítsd a saját szervered. Kezdd a szerver elnevezésével."])},
    "tabs": {
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakozz"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Készíts"])}
    }
  },
  "presence": {
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elérhető"])},
    "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El menve"])},
    "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foglalt"])},
    "looking-to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Játszani Szeretne"])}
  },
  "right-drawer": {
    "server-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tagok (", _interpolate(_list(0)), ")"])},
    "server-offline-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Offline (", _interpolate(_list(0)), ")"])}
  },
  "profile-popout": {
    "common-friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közös Barátok"])},
    "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelvények"])},
    "joined-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakozott"])},
    "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felfüggesztett"])},
    "remove-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barát Eltávólítása"])},
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérés Elfogadása"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérés Lemondása"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barát Bejelölése"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzenet Küldése"])},
    "common-servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közös Szerverek"])}
  },
  "connection": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betöltés..."])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Készen áll!"])},
    "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítés..."])}
  },
  "navbar": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítés"])},
    "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítés Elérhető"])}
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Másolás"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Másolva!"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználó"])}
  },
  "messages": {
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kilépett a szerverből"])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ki lett rugatva"])},
    "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ki lett bannolva"])},
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["csatlakozott a szerverbe!"])}
  },
  "could-not-connect-to-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem tudott csatlakozni a szerverbe."])},
  "chat-area": {
    "rate-limit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate Limit Mód"])}
  }
}